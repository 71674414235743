<template>
  <v-dialog
    :value="isOpen"
    max-width="500px"
    @input="handleCancel"
  >
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>

      <v-card-text>
        <div
          v-if="text"
          class="text-subtitle-1 mb-4 pb-4 pt-4"
        >
          {{ text }}
        </div>
        <div
          v-if="subText"
          class="text-caption mb-2"
        >
          {{ subText }}
        </div>
        <slot />
      </v-card-text>

      <v-card-actions v-if="(handleCancel || handleSubmit) && !(!!this.$slots.actions)">
        <v-btn 
          v-if="handleCancel"
          outlined
          color="red darken-2"
          @click="handleCancel"
        >
          <v-icon right>
            mdi-cancel
          </v-icon>{{ $t('forms.buttons.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="handleSubmit"
          raised
          color="green darken-1"
          @click="handleSubmit"
        >
          <v-icon left>
            mdi-check-outline
          </v-icon>{{ $t('forms.buttons.validate') }}
        </v-btn>
      </v-card-actions>
      <v-card-actions v-if="!!this.$slots.actions">
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ConfirmModal',
  props: {
    isOpen: { default: false, type: Boolean },
    title: { default: '', type: String },
    text: { default: '', type: String },
    subText: { default: '', type: String },
    handleSubmit: { default: undefined, type: Function },
    handleCancel: { required: true, type: Function },
  },
}

</script>
