export function createQueryString(data, parentKey = '') {
  return Object.keys(data)
    .map(key => {
      const value = data[key]
      if (value === undefined || value === '' || value === null) return

      if (typeof value === 'object') {
        return createQueryString(value, key)
      }
      if (parentKey) {
        if (key.match(/\d+/)) {
          return `${parentKey}=${encodeURIComponent(value)}`
        }
        else {
          return `${parentKey}[${key}]=${encodeURIComponent(value)}`
        }
      }

      return `${key}=${encodeURIComponent(value)}`
    })
    .filter(value => value).join('&')
}
